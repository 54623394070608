.welcome-step {
    text-align: center;
    min-height: 250px;
    display: flex !important;
    margin-top: 2rem;

    .welcome-description {
        font-size: 1rem;
        color: var(--text-secondary);
        margin-top: 1rem;
    }

    .welcome-time {
        font-size: 0.875rem;
        color: var(--text-tertiary);
    }

    .button.start-button {
        padding: .8rem;
        width: 25rem;
        font-size: 1.125rem;
        margin-top: 1rem;
    }
}