.list.positions-list {
    & .list-item {

        & .card {
            border: .15rem solid transparent !important;
            border-radius: var(--border-radius);
            padding: var(--padding-quaternary) var(--padding-primary);
        }

        & .card:hover {
            background-color: #eeeeee;
        }

        & .expandable.d-none {
            animation: collapseListItem 500ms ease-in-out;
            height: 0%;
            overflow: hidden;
        }

        & .expandable:not(.d-none) {
            animation: expandListItem 500ms ease-in-out;
            border: .15rem solid var(--platinum);
            border-radius: 0 0 var(--border-radius) var(--border-radius);
            border-top: unset;
            height: 100%;
            overflow: hidden;
        }

        & .card:has(+ .expandable:not(.d-none)) {
            border: .15rem solid var(--platinum) !important;
            border-radius: var(--border-radius) var(--border-radius) 0 0;
            border-bottom: unset !important;
        }

        & .currency {
            font-size: small;
        }
    }

    & .list-item:hover {
        background-color: unset !important;
    }
}

body.dark .list.positions-list {
    & .list-item {
        & .card:hover {
            background-color: var(--jet);
        }

        & .expandable:not(.d-none) {
            border: .15rem solid var(--jet);
            border-top: unset;
        }

        & .card:has(+ .expandable:not(.d-none)) {
            border: .15rem solid var(--jet) !important;
            border-bottom: unset !important;
        }
    }
}
