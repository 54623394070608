.welcome-step {
    text-align: center;
    min-height: 250px;
    display: flex !important;
    margin-top: 2rem;

    .welcome-description {
        font-size: 1rem;
        color: var(--text-secondary);
        margin-top: 1rem;
    }

    .welcome-time {
        font-size: 0.875rem;
        color: var(--text-tertiary);
    }
}