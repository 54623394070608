.card {
    border: none !important;

    & .card-title {
        color: var(--color-secondary);
        font-size: x-small;
        font-weight: 400;
    }

    /* COIN TREND DIRECTION ================= */

    &.coin-trend-direction {
        padding: unset;
    }

    &.coin-trend-direction .card-direction {
        margin-right: var(--margin-primary);
        text-align: center;
    }

    &.coin-trend-direction #card-row-0-0 {
        align-items: center;
        display: flex;
    }

    &.coin-trend-direction #card-row-0-0 img {
        height: 2rem;
    }

    &.coin-trend-direction #card-row-0 p {
        margin-left: .5rem;
    }

    &.coin-trend-direction .termometer {
        background-color: var(--anti-flash-white);
        height: 1rem;
        width: .5rem;
    }

    &.coin-trend-direction .termometer.bar-0 {
        margin-bottom: .25rem;
    }

    &.coin-trend-direction .bearish {
        color: var(--indian-red);
    }

    &.coin-trend-direction .bullish {
        color: var(--jungle-green);
    }

    &.coin-trend-direction .neutral {
        color: var(--dim-gray);
        opacity: .25;
    }

    &.coin-trend-direction .somewhat-bearish {
        color: var(--indian-red);
        opacity: .5;
    }

    &.coin-trend-direction .somewhat-bullish {
        color: var(--jungle-green);
        opacity: .5;
    }

    /* Info ================================= */

    &.info {
        box-shadow: var(--img-box-shadow);
        position: absolute;
        width: 20rem;
        z-index: 1000;
    }

    &.info p {
        font-weight: normal;
        width: var(--fill-all);
    }

    /* SINGLE TICKER ======================== */

    &.single-ticker {
        padding: unset;
    }

    &.single-ticker #card-row-1 {
        margin-top: 0;
    }

    &.single-ticker #card-row-1 .row {
        margin: unset;
    }

    &.single-ticker #card-row-1-1 p {
        margin-left: .5rem;
    }

    &.single-ticker #card-row-0-0 {
        margin-right: .5rem;
        position: relative;
        width: 2.25rem;
        height: 2.5rem;
    }

    &.single-ticker #card-row-0-0 img {
        position: absolute;
    }

    &.single-ticker #card-row-0-0 img:first-child {
        right: 0;
        width: 1.25rem;
    }

    &.single-ticker #card-row-0-0 img:last-child {
        border: .2rem solid var(--container-bg-color);
        border-radius: 100%;
        bottom: 0;
        width: 1.5rem;
    }

    &.single-ticker #card-row-0-1 {
        display: flex;
        justify-content: space-between;
    }

    &.condition-resume {
        border: var(--border-tertiary) !important;
        cursor: pointer;

        & .column {
            gap: .5rem;
        }

        & .row {
            transition: var(--transition-primary);
        }

        & .condition-resume-params .row:hover {
            background-color: var(--anti-flash-white);
        }
    }
}

body.dark .card {
    border: none !important;

    &.article-preview {
        &:hover {
            background: var(--jet);
        }
    }

    &.single-ticker #card-row-0-0 img:last-child {
        border: .2rem solid var(--container-bg-color-dark);
    }

    &.condition-resume {
        border: var(--border-tertiary-dark) !important;

        & .condition-resume-params .row:hover {
            background-color: var(--jet);
        }
    }
}