body.dark .ant-list-split .ant-list-item {
    border-block-end: 1px solid rgba(5, 5, 5, 0.5);
}

body.dark .ant-card {
    color: var(--color-primary-dark);
    background: var(--container-bg-color-dark);
    border: 1px solid var(--container-bg-color-dark);
    box-shadow: var(--container-box-shadow);
}

.ant-modal {
    .ant-modal-content {
        border-radius: var(--border-radius);
        padding: var(--padding-tertiary);
    }
}

body.dark .ant-modal {

    .ant-modal-header,
    .ant-modal-content {
        background-color: var(--container-bg-color-dark);

        .ant-modal-close-x {
            color: var(--color-quaternary) !important;
        }
    }
}

.ant-steps-item-title {
    font-size: x-small !important;
}

.ant-tooltip-inner * {
    color: white !important;
}

body.dark .ant-empty-description {
    color: var(--color-primary-dark);
}

body.dark .ant-typography {
    color: var(--color-primary-dark);
}

.ant-dropdown {
    & .ant-dropdown-menu {
        background-color: var(--container-bg-color) !important;
    }

    & .ant-dropdown-menu-item-group-title {
        color: var(--color-primary) !important;
    }

    & .ant-dropdown-menu-item {
        padding: 0;

        &:hover {
            background-color: unset !important;
        }
    }
}

body.dark .ant-dropdown {
    & .ant-dropdown-menu {
        background-color: var(--container-bg-color-dark) !important;
    }

    & .ant-dropdown-menu-item-group-title {
        color: var(--color-primary-dark) !important;
    }
}

.ant-select-selection-placeholder,
.ant-picker-input input::placeholder,
.ant-picker-range-separator {
    color: var(--color-tertiary) !important;
}

body.dark .ant-select-selection-placeholder,
body.dark .ant-picker-input input::placeholder,
body.dark :is(.ant-picker-range-separator, .ant-picker-suffix, .anticon-close-circle) svg {
    color: var(--color-tertiary) !important;
}

body.dark .ant-picker-input input {
    color: var(--color-primary-dark) !important;
}

body.dark .ant-picker-panel-layout {
    background-color: var(--container-bg-color-dark) !important;

    & .ant-picker-header,
    .ant-picker-body .ant-picker-content :is(th, td),
    .ant-picker-super-prev-icon,
    .ant-picker-prev-icon,
    .ant-picker-next-icon,
    .ant-picker-time-panel-cell-inner,
    .ant-picker-super-next-icon {
        color: var(--color-primary-dark) !important;
    }

    & :is(.ant-picker-cell-in-range, .ant-picker-time-panel-cell-selected) div {
        color: var(--color-primary) !important;
    }
}

.ant-select-selector,
.ant-select-selection-item-content,
.ant-select-selection-item-remove,
.ant-select-selection-item,
.ant-select-arrow {
    color: var(--color-primary) !important;
}

:is(body.dark, .quick-settings) :is(.ant-select-selector,
    .ant-select-selection-item-content,
    .ant-select-selection-item-remove,
    .ant-select-selection-item,
    .ant-picker.ant-picker-outlined,
    .ant-select-arrow,
    .ant-popover-title) {
    color: var(--color-primary-dark) !important;
    border-color: var(--jet) !important;
}

body.dark .ant-radio-button-wrapper {
    background-color: transparent;
}

body.dark .ant-select-multiple .ant-select-selection-item,
body.dark .ant-select-item-option-selected,
body.dark .ant-select-selector,
body.dark .ant-picker.ant-picker-outlined,
body.dark .ant-modal-content .ant-select-selector {
    background-color: var(--resin-black) !important;
}

body.dark .ant-select-dropdown,
body.dark .ant-popover .ant-popover-inner {
    background-color: var(--container-bg-color-dark);

    & :not(.ant-select-item-option-disabled) .ant-select-item-option-content {
        color: var(--color-primary-dark);
    }

    & .ant-select-item-option-disabled .ant-select-item-option-content {
        color: var(--color-secondary);
    }

    & .ant-select-item:hover {
        background-color: var(--jet);
    }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    border-color: var(--united-nations-blue) !important;
    color: var(--united-nations-blue) !important;
}

body.dark .ant-slider-rail {
    background-color: var(--jet) !important;
}

body.dark .ant-checkbox {
    & .ant-checkbox-inner {
        border-color: var(--onyx) !important;
    }

    &.ant-checkbox-disabled {
        & .ant-checkbox-inner {
            background-color: var(--onyx) !important;
        }
    }

    &:not(.ant-checkbox-checked) {
        & .ant-checkbox-inner {
            background-color: unset;
        }
    }
}