.card-period-trend-variation {
    height: auto !important;

    padding: var(--padding-small) !important;

    user-select: none;
    cursor: pointer;

    color: var(--white);
}

.browser .card-period-trend-variation {
    display: flex;
    align-items: center;
}

.mobile .card-period-trend-variation :first-child {
    width: 5rem;
}

.mobile .card-period-trend-variation :first-child .value {
    margin: .25rem 0 .5rem 0;
}

.card-period-trend-variation :first-child {
    width: 6.75rem;
}

.card-period-trend-variation :last-child {
    width: 100%;
    height: 1.5rem !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.browser .card-period-trend-variation .label {
    display: flex;
    font-size: medium;
}

.mobile .card-period-trend-variation .label {
    display: flex;
    font-size: x-small;
}

.card-period-trend-variation .value {
    line-height: 2rem;
    font-size: medium;
    font-weight: bold;
    text-align: center;
}

.card-period-trend-variation img {
    -webkit-user-drag: none;

    width: 2.25rem;

    color: var(--white);
}
