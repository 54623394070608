.panel {
    display: flex;
    flex-direction: column;
    gap: var(--gap-primary);

    & .empty-img {
        opacity: .1;
        width: 10rem;
    }

    &.fit-height {
        min-height: unset;
    }
    
    &:is(.fill-all,.fill-height) {
        max-height: unset;
    }
}

body.dark .panel .empty-img {
    filter: invert(1);
}