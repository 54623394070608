#ats-settings {

    & #ats-panel {
        height: 20rem !important;
    }

    & #pair-allocations {
        & #pair-allocations-list {
            height: calc(100% - 7rem) !important;
            
            & .pair-allocation-item {
                cursor: pointer;
                border-radius: var(--border-radius);
                padding: var(--padding-primary);
                transition: var(--transition-primary);

                &:hover {
                    background: var(--anti-flash-white);
                }

                &.selected {
                    background: var(--anti-flash-white);
                }
            }
        }
    }
}

body.dark #ats-settings {
    & #pair-allocations {
        & #pair-allocations-list {
            & .pair-allocation-item {
                &:hover {
                    background: var(--jet);
                }

                &.selected {
                    background: var(--jet);
                }
            }
        }
    }
}