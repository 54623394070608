/** Initial Animations */

@keyframes logo-initial-center {
  0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes logo-center-to-left {
  0% {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      filter: none;
      opacity: 1;
  }
  100% {
      left: 0;
      top: 0;
      transform: translate(-50%, 20%) scale(1);
      filter: brightness(0) invert(1);
      opacity: 0.25;
  }
}

@keyframes fade-overlay {
  0% {
      background-color: rgba(0, 0, 0, 0.8);
  }
  100% {
      background-color: rgba(0, 0, 0, 0);
  }
}

@keyframes form-slide-in {
  0% {
      opacity: 0;
      transform: translateX(100px);
  }
  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

/** Exit Animations */

@keyframes fade-overlay-out {
  0% {
      background-color: rgba(0, 0, 0, 0);
  }
  100% {
      background-color: rgba(0, 0, 0, 0.8);
  }
}

@keyframes logo-left-to-center {
  0% {
      left: 0;
      top: 0;
      transform: translate(-50%, 20%) scale(0.8);
      filter: brightness(0) invert(1);
      opacity: 0.5;
  }
  100% {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1);
      filter: none;
      opacity: 1;
  }
}

@keyframes form-fade-out {
  0% {
      opacity: 1;
      right: 5rem;
  }
  100% {
      opacity: 0;
      right: -100%;
  }
}

@keyframes smart-trade-logo-out {
  0% {
      opacity: 1;
  }
  100% {
      opacity: 0;
  }
}

#reset-password-page-base {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100vw;

  & #background-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      animation: fade-overlay 6s ease-out forwards;
  }

  & #bnx-logo {
      height: 70vh;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      -webkit-user-drag: none;
      user-select: none;
      z-index: 1;
      animation: logo-initial-center 1s ease-out forwards,
                 logo-center-to-left 1s ease-out 2s forwards;
  }

  & #smart-trade-logo {
      animation: sign-in-logo-in 1.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 3s forwards;
      border-radius: unset;
      left: 14rem;
      position: absolute;
      transform: translate(20%, -50%);
      top: 50%;
      -webkit-user-drag: none;
      user-select: none;
      width: 20rem;
      opacity: 0;
  }

  & #reset-password-parent {
      position: absolute;
      right: 16rem;
      width: 26rem;
      opacity: 0;
      animation: form-slide-in 1s ease-out 3s forwards;
      z-index: 2;
  }

  & #reset-password-panel {
      background-color: rgba(32, 31, 34, 0.8);
      form {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          button {
              margin-top: 2rem;
              height: 42px;
          }

          input {
              margin-left: unset;
              margin-right: unset;
          }
      }

      & #reset-password-form {

        & #password-msg {
          height: 1rem;
          animation: fadeIn .5s linear;
          color: var(--indian-red);
        }
      }
  }

  & .password-hints {
    width: inherit;
    height: fit-content;
  }

  & #icon-container .icon-svg {
    color: var(--united-nations-blue);
    margin: 0;
  }

  & input {
    margin-left: 0;
    margin-right: 0;
  }

  &.leaving {
      & #background-overlay {
          animation: fade-overlay-out 1s ease-out forwards;
      }

      & #bnx-logo {
          animation: logo-left-to-center 1s ease-out forwards;
      }

      & #reset-password-parent {
          animation: form-fade-out 1s ease-out forwards;
          position: absolute;
      }

      & #smart-trade-logo {
          animation: smart-trade-logo-out 0.5s ease-out forwards;
      }
  }

  .quick-settings {
      animation: sign-in-panel-in 1.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      z-index: 3;
      padding-bottom: .5rem;
      position: absolute;
  }
}

@media (width < 1200px) {
  #reset-password-page-base {
      & #bnx-logo {
          display: none;
      }
      
      & #smart-trade-logo {
          display: none;
      }

      & #reset-password-panel {
          position: relative;
          right: unset;
          opacity: 1;
          animation: none;
      }
  }
}

body.bright #reset-password-page-base #reset-password-panel {
  background-color: rgba(255, 255, 255, 0.8) !important;
}