/** Initial Animations */

@keyframes logo-initial-center {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes logo-fade-out {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8);
    }
}

@keyframes fade-overlay {
    0% {
        background-color: rgba(0, 0, 0, 0.8);
    }
    100% {
        background-color: rgba(0, 0, 0, 0);
    }
}

@keyframes form-fade-in {
    0% {
        opacity: 0;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/** Exit Animations */

@keyframes fade-overlay-out {
    0% {
        background-color: rgba(0, 0, 0, 0);
    }
    100% {
        background-color: rgba(0, 0, 0, 0.8);
    }
}

@keyframes logo-left-to-center {
    0% {
        left: 0;
        top: 0;
        transform: translate(-50%, 20%) scale(0.8);
        filter: brightness(0) invert(1);
        opacity: 0.5;
    }
    100% {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1);
        filter: none;
        opacity: 1;
    }
}

@keyframes form-fade-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.95);
    }
}

@keyframes smart-trade-logo-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

#onboarding-page-base {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100vh;
    justify-content: center;
    position: relative;
    width: 100vw;

    & .title {
        overflow: unset;
    }

    & .panel {
        max-height: 40rem !important;
    }

    & #step-content {
        max-height: 32rem;
    }

    & #background-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        animation: fade-overlay 6s ease-out forwards;
    }

    & #bnx-logo {
        height: 70vh;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-user-drag: none;
        user-select: none;
        z-index: 2;
        animation: logo-initial-center 1s ease-out forwards,
                   logo-fade-out 1s ease-out 2s forwards;
    }

    & #smart-trade-logo {
        border-radius: unset;
        position: relative;
        top: unset;
        right: .5rem;
        transform: none;
        -webkit-user-drag: none;
        filter: invert(1);
        user-select: none;
        width: 12rem;
        opacity: 1;
    }

    & input {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    & .password-hints {
        width: inherit;
        height: fit-content;
        user-select: none;
    }

    & #icon-container {
        margin-left: 0;
    }

    & #hints-info-popup-container {
        max-width: 30rem;
        min-width: 15rem;
        position: absolute;
        margin: -2.5rem 0 0 21rem;
        border-radius: var(--border-radius);
        box-shadow: var(--container-box-shadow);
        z-index: 999;
        display: none
    }

    & #hints-info-popup-container #info-popup {
        height: fit-content;
        font-weight: bold;
        padding: .75rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        opacity: .75;
    }

    & #onboarding-parent {
        position: relative;
        width: 46rem;
        opacity: 0;
        z-index: 2;
        transform: translateX(-50%);
        animation: form-fade-in 1s ease-out 3s forwards;

        & #onboarding-form {

            & #password-msg {
                height: 1rem;
                animation: fadeIn .5s linear;
                color: var(--indian-red);
            }

            & #mfa-code-img {
                border-radius: var(--border-radius);
                width: 10rem !important;
            }

            & #mfa-code-img-loading {
                display: flex;
                justify-content: center;
                padding: 4rem 4rem;

                & img {
                    filter: invert(.75);
                    height: 2rem;
                }
            }

            & .steps-flow-step-footer {
                height: 1rem;

                & .link {
                    animation: fadeIn .5s linear;
                }
            }
        }
    }

    & #onboarding-panel {
        background-color: rgba(32, 31, 34, 0.9);
        form {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            button {
                height: 42px;
            }

            input {
                margin-left: unset;
                margin-right: unset;
            }
        }
    }

    &.leaving {
        & #background-overlay {
            animation: fade-overlay-out 1s ease-out forwards;
        }

        & #bnx-logo {
            animation: logo-left-to-center 1s ease-out forwards;
        }

        & #onboarding-parent {
            animation: form-fade-out 1s ease-out forwards;
            position: absolute;
        }

        & #smart-trade-logo {
            animation: smart-trade-logo-out 0.5s ease-out forwards;
        }
    }

    .quick-settings {
        animation: sign-in-panel-in 1.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        z-index: 3;
        padding-bottom: .5rem;
        position: absolute;
    }
}

@media (width < 1200px) {
    #onboarding-page-base {
        & #bnx-logo {
            display: none;
        }
        
        & #smart-trade-logo {
            display: none;
        }

        & #onboarding-panel {
            position: relative;
            right: unset;
            opacity: 1;
            animation: none;
        }
    }
}

body.bright #sign-in-page-base #sign-in-panel {
    background-color: rgba(255, 255, 255, 0.8) !important;
}