#news {

    & #top10-card-info {
        display: none;
        left: 0;
        top: 2.75rem;
    }

    & #top10-info:hover~#top10-card-info,
    #top10-card-info:hover {
        display: block;
    }

    & .main-row {
        & #article-emphasis, #article-container {
            box-sizing: border-box;
            height: inherit;
            max-height: unset;
        }
    }

    & #news-gradient-container {
        width: var(--fill-all);

        display: flex;
        justify-content: center;
        align-items: center;

        animation: settingsExpandGradient linear var(--transition-secondary);
        background-image: var(--gradient-0);
    }

    & .news-container {
        margin: 1rem 0rem;

        & .column {
            width: var(--fill-all);
        }

        & .row {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;

            & span {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            & :is(.enabled-mfa-email-text, .enabled-mfa-app-text) {
                width: 11rem;
                margin-left: 1rem;
            }

            & .news-container .news-cards {
                display: flex;
            }
        }

        & :is(#user-info, #user-wallet, #user-wallet, #user-security) {
            width: fit-content;
            height: fit-content;
            display: flex;
        }

        & .news-cards .container {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            margin: 1rem;
        }
    }

    & #news-middle-column {
        width: 44rem;
    }

    & .top-coins-ticker {
        height: 2.75rem;
        width: 70rem;
    }

    & #last-news {

        & #header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        & .content .row {
            display: flex;
            flex-direction: row;
        }
    }

    & .news-topics {
        display: flex;

        & span {
            cursor: pointer;
            box-shadow: var(--container-box-shadow);
            font-size: small;
            font-weight: 500;
            padding: .5rem 1rem;
            margin-right: .5rem;
            user-select: none;
        }

        & span.selected {
            background: var(--anti-flash-white);
        }

        & span:not(.selected):hover {
            background: var(--ghost-white);
        }
    }

    & :is(#trends-filters, #last-news-filters) button {
        font-size: x-small;
        margin-left: 0;
    }

    & #article-emphasis {

        & #banner {
            border-radius: var(--border-radius);
            height: 25rem;
            object-fit: cover;
            width: 100%;
        }

        & #article-header img {
            height: fit-content;
            max-height: 2rem;
            max-width: 12rem;
        }

        & p {
            margin: 0;
            text-align: justify;
        }

        & #article-footer #topics p {
            background: var(--ghost-white);
            border-radius: var(--border-radius);
            box-shadow: var(--container-box-shadow);
            color: var(--dim-gray);
            font-weight: 500;
            margin: 0 .5rem .5rem 0;
            padding: .25rem .5rem;
            width: fit-content;
        }
    }

    & #articles-list {
        overflow: auto;
        padding-right: var(--padding-primary);
        width: 25rem;
    }

    & .list-message {
        color: var(--color-secondary);
    }

    & .source-image {
        border-radius: unset;
    }
}

body.dark #news {
    & #news-gradient-container {
        background-image: var(--gradient-1);
    }

    & #article-emphasis #article-header img {
        filter: saturate(0) invert(.5);
    }
}