.connect-exchanges-step {
    width: 100%;

    .exchange-form-panel,
    .connected-exchanges-panel {
        padding: 1rem;
        border: 1px solid var(--border-color);
        border-radius: var(--border-radius);
        background-color: var(--background-color);
    }

    .continue-button {
        margin-top: 1rem;
        width: 100%;
        max-width: 320px;
        padding: var(--padding-primary);
    }

    .title {
        text-align: center;
        width: 100%;
    }
    
    .user-exchanges {
        min-width: 21rem;
    }
}

.connect-button-container {
    width: 100%;
}

.connect-exchange-button {
    width: 100%;
    background-color: var(--btn-primary-bg-color);
    color: var(--btn-primary-color);
    border: none;
    padding: var(--padding-primary);
    margin-top: .5rem;
    font-size: 13px;
    text-align: center;
}

.connect-exchange-button:disabled {
    background-color: var(--btn-primary-bg-color-disabled);
    cursor: not-allowed;
    opacity: 0.6;
}