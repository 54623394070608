.assets-pill {
    border-radius: 10rem;
    border: var(--border-tertiary);
    cursor: pointer;
    padding: .25rem;
    max-width: 5rem;

    &:hover {
        background-color: #eeeeee;
    }

    & p {
        margin-right: .25rem;
    }
}

body.dark .assets-pill {
    border: var(--border-tertiary-dark) !important;

    &:hover {
        background-color: var(--jet);
    }
}