.wallet-step {
    .title {
        text-align: center;
        width: 100%;
    }

    .notice {
        border-radius: var(--border-radius);
        background-color: rgba(65, 173, 250, 0.5);
        padding: var(--padding-primary);
    }

    .continue-button {
        margin-top: 1rem;
        width: 100%;
        max-width: 320px;
        padding: var(--padding-primary);
    }

    .header {
        background: transparent !important;
    }

    .selected-count {
        padding-left: 2rem;
    }

    .coins-list {
        flex: 1;
        overflow-y: auto;
        padding-right: 0.5rem;

        .coin-item {
            padding: 0.25rem 0;
            border-bottom: 1px solid var(--border-color);

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .available-cryptos,
    .monitored-cryptos {
        background: var(--panel-background);
        border-radius: var(--border-radius);
        padding: var(--padding-primary);
        height: 15rem;
        display: flex;
        flex-direction: column;
        
    }

    .label {
        &.coin-info {
            & .coin-name {
                width: 8rem;
            }
        }
    }

    .header {
        padding: 0.5rem 0rem;
    }

    .selected-count {
        padding-left: 0rem !important;
    }

    .total-count {
        padding-left: 1rem !important;
    }
}