#wallet {
    & .content {
        height: calc(100% - 4rem);
    }

    & #move-buttons {
        margin: 0 var(--margin-secondary) 0 var(--margin-primary);
    }

    & .portfolio-header, .portfolio-col {
        & .col-0 {
            width: 10rem !important;
        }

        & .col-1 {
            width: 13rem !important;
        }

        & .col-2 {
            width: 5rem !important;
        }
    }

    & .portfolio-header {
        margin-right: 1rem;
    }
}