.ats-step {
    padding: var(--padding-primary);
    max-width: 100%;

    .continue-button {
        margin-top: 1rem;
        width: 100%;
        max-width: 320px;
        padding: var(--padding-primary);
    }

    .section-label {
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    .allocation-toggle {
        background: var(--background-secondary);
        border-radius: var(--border-radius);
        padding: 2px;
        margin-bottom: 1rem;

        button {
            flex: 1;
            padding: 0.5rem;
            border: none;
            background: none;
            border-radius: calc(var(--border-radius) - 2px);
            cursor: pointer;
            
            &.active {
                background: var(--primary-color);
                color: white;
            }
        }
    }

    .minimum-text {
        color: var(--text-secondary);
        font-size: 0.875rem;
        margin-top: 0.5rem;
    }

    .strategies-wrapper {
        width: 100%;
        overflow-x: auto;
        padding-bottom: 1rem;
    }

    .strategies-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(300px, 1fr));
        min-width: min-content;
        width: 100%;
        gap: 1rem;
    }

    .strategy-card {
        background: var(--background-dark);
        border-radius: var(--border-radius);
        padding: var(--padding-primary);
        cursor: pointer;
        min-width: 300px;
        transition: all 0.2s ease;
        border: 2px solid transparent;

        &:hover {
            transform: translateY(-2px);
        }

        &.selected {
            border-color: var(--primary-color);
        }

        .strategy-image {
            width: 100%;
            height: 120px;
            object-fit: cover;
            border-radius: var(--border-radius);
            margin-bottom: 1rem;
        }

        h3 {
            margin: 0;
            font-size: 1.1rem;
        }

        .strategy-assets {
            flex-wrap: wrap;
            
            .asset-pill, .quote-pill {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                padding: 0.25rem 0.5rem;
                border-radius: 1rem;
                background: var(--background-lighter);
                font-size: 0.875rem;

                img {
                    width: 16px;
                    height: 16px;
                }
            }

            .quote-pill {
                opacity: 0.7;
            }
        }

        .strategy-metrics {
            font-size: 0.875rem;
            color: var(--text-secondary);

            img {
                width: 16px;
                height: 16px;
            }

            .performance {
                color: var(--success-color);
            }
        }
    }

    .title {
        text-align: center;
        width: 100%;
    }
}

body.dark .ats-step {
    .strategy-card {
        background: var(--background-darker);
    }

    .allocation-toggle {
        background: var(--background-darker);
    }

    .asset-pill, .quote-pill {
        background: var(--background-darkest) !important;
    }
}