.table.orders {

    & #orders-table-header,
    .table-item {
        padding-right: var(--padding-secondary);
    }

    & .col-0 {
        width: 2rem !important;
        text-align: center;
    }

    & .col-1 {
        width: 10rem !important;
    }

    & .col-2 {
        width: 7rem !important;
    }

    & .col-3 {
        width: 10rem !important;
    }

    & .col-4 {
        width: 5rem !important;
    }

    & label.col-5 {
        padding: .25rem .5rem;
    }

    & .col-5 {
        width: 3rem !important;
        text-align: center;
    }

    & .col-6 {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 3rem !important;
    }

    & .col-7 {
        width: 6rem !important;
        text-align: center;
    }
}