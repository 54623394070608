.table.signals {

    & #signals-table-header,
    .table-item {
        padding: 0 var(--padding-secondary);
    }

    .table-item {
        border-radius: var(--border-radius);

        &:hover {
            background-color: #eeeeee !important;
        }
    }

    & .col-0 {
        width: 6rem !important;
    }

    & .col-1 {
        width: 7rem !important;
    }

    & .col-2 {
        width: 10rem !important;
    }

    & .col-3 {
        width: 5rem !important;
    }

    & label.col-4 {
        padding: .25rem .5rem;
    }

    & .col-4 {
        width: 2rem !important;
        text-align: center;
    }

    & .col-5 {
        width: 3rem !important;
        text-align: center;
    }

    & .col-6 {
        width: 3rem !important;
        text-align: center;
    }
}

body.dark .table.signals .table-item:hover {
    background-color: var(--jet) !important;
}