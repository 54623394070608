
.background {
    width: 100vw;
    height: 100vh;

    position: fixed;

    background: rgba(0, 0, 0, 0.75);
    z-index: 1002;

    display: flex;
    align-items: center;
    justify-content: center;
}
