.personal-info-step {
    width: 100%;
    
    .profile-picture-section {
        .helper-text {
            color: var(--text-secondary);
            font-size: 0.875rem;
            text-align: center;
        }
    }

    .form-fields-section {
        flex: 1;
    }

    .label {
        color: var(--text-primary);
    }

    .error-text {
        color: #ff4d4f;
        font-size: 0.85em;
        margin-top: 4px;
    }
    
    .verification-container {
        margin-top: 8px;
    }

    .investor-profile-card {
        cursor: pointer;
        transition: all 0.3s ease;
        flex: 1;
        border-color: transparent !important;
        background-color: rgba(80, 80, 80, 0.1) !important;

        &.selected {
            border-color: var(--color-primary) !important;
            background-color: rgba(80, 80, 80, 0.1);
        }

        h3 {
            margin-bottom: 0.5rem;
        }

        p {
            color: var(--text-secondary);
            font-size: 0.875rem;
        }
    }

    .continue-button {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        padding: var(--padding-primary);
    }

    .title {
        text-align: center;
        width: 100%;
    }

    .profile-picture-section .title {
        text-align: center;
        width: 100%;
    }
}