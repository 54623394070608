
.user-card {
    border: .1rem solid var(--anti-flash-white);
    border-radius: var(--border-radius);
    padding: 1rem;
}

body.dark .user-card {
    border-color: var(--onyx) !important;
}
