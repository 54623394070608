.list.user-exchanges {
    & .item {
        border: 1px solid var(--anti-flash-white);
        border-radius: var(--border-radius);
        user-select: none;
    }

    & .title {
        font-size: medium;
    }

    & .input {
        padding: 0 var(--padding-secondary)
    }

    & .switchable-icon {
        margin-left: .5rem;
        padding: .4rem;
    }

    & .icon-svg {
        width: 1rem;
    }
}

body.dark .list.user-exchanges .item {
    border-color: var(--jet);
}