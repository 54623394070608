.step-secure-password {
    width: 100%;

    .title {
        text-align: center;
        width: 100%;
    }

    .continue-button {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        padding: var(--padding-primary);
    }

    .password {
        width: 50%;
    }

    .bar {
        width: 50% !important;
    }
}