.step-terms-policies {
    width: 100%;
    height: 100%;

    .title {
        text-align: center;
        width: 100%;
        background-color: var(--color-background-tertiary);
        border-radius: var(--border-radius) var(--border-radius) 0 0;
        margin: 0;
    }

    .continue-button {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        padding: var(--padding-primary);
    }

    .document-container {
        background-color: #525659;
        border-radius: var(--border-radius);
        padding: var(--padding-secondary);
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -20px;
            width: 20px;
            height: 20px;
            background-color: var(--color-success);
            border-radius: 50%;
            display: none;
        }

        &.read::before {
            display: block;
        }
    }

    iframe {
        width: 100%;
        height: 100%;
        min-height: 150px;
        border: none;
    }

    .text-lg {
        color: var(--color-text-primary);
        font-weight: 500;
    }
}