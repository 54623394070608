
.all-page-content {
  display: flex !important;
  gap: 0;
  height: 100vmin;
  width: var(--fill-all);
}

.main-row {
  height: calc(100vh - 5rem) !important;
}

.ant-segmented {
  background-color: transparent !important;
  border: 1px solid #FFF !important;
  border-radius: var(--border-radius) !important;

  & .ant-segmented-item {
    align-items: center;
    border-radius: calc(var(--border-radius) - 2px) !important;
    display: flex;
    height: 2rem !important;
  }

  & .ant-segmented-group {
    height: 2rem !important;
  }

  & .ant-segmented-item-label {
    color: #FFF;
  }

  & .ant-segmented-item-selected .ant-segmented-item-label {
    color: #101010 !important;
  }
}

.version {
  bottom: 0;
  color: var(--color-primary);
  font-size: small;
  padding: var(--padding-quaternary);
  position: absolute;
  right: 0;
}

.quick-settings.basic .select-overlap * {
  color: var(--img-show-icon-color) !important;
}

#bnx-logo-header {
  border-radius: unset;
  height: 2.5rem;
}

#smart-trade-logo-header {
  border-radius: unset;
}

body.dark #smart-trade-logo-header {
  filter: invert(1);
}

@media (width >= 1200px) {
  .layout #logo img {
    display: none;
  }
}