.card.strategy-overview {
    border-radius: var(--border-radius);
    cursor: pointer;
    outline: .15rem solid var(--anti-flash-white);
    padding: var(--padding-primary);
    width: 35rem;
    user-select: none;

    &:is(:hover, .selected) {
        background-color: var(--anti-flash-white);
    }
}

body.dark .card.strategy-overview {
    outline-color: var(--jet);

    &:is(:hover, .selected) {
        background-color: var(--jet);
    }
}