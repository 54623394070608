.badge {
    align-items: center;
    border-radius: var(--border-radius);
    border: .1rem solid var(--onyx);
    box-shadow: var(--container-box-shadow);
    color: var(--color-primary);
    display: flex;
    font-size: small;
    gap: .5rem;
    justify-content: center;
    padding: .25rem .5rem;
    user-select: none;

    & .icon-svg {
        color: var(--onyx);
        height: 1rem;
        width: 1rem;
    }
}

.badge :is(b, p) {
    text-wrap: nowrap;
}

body.dark .badge {
    color: var(--color-primary-dark);
}

.badge.primary {
    border-color: var(--united-nations-blue);
}

.badge.secondary {
    background-color: #eeeeee;
    border-color: var(--silver);
    color: var(--color-primary) !important;
}

.badge.buy {
    background: var(--trend-up-bg-color) !important;
    border-color: var(--trend-up-color);
    color: var(--trend-up-color) !important;
}

.badge.sell {
    background: var(--trend-down-bg-color) !important;
    border-color: var(--trend-down-color);
    color: var(--trend-down-color) !important;
}

.badge.admin {
    background: rgb(255, 85, 85);
    border-color: red;
    color: var(--ghost-white) !important;
    font-size: x-small !important;
}

.badge:is(.relevant, .non-relevant) {
    border-radius: 10rem;
    font-size: x-small;
    font-weight: bold;
    padding: var(--padding-quinarynary) var(--padding-primary);
}

.badge.relevant {
    border-color: var(--sea-green);

    & span {
        color: var(--sea-green);
    }
}

.badge.non-relevant {
    border-color: #eeeeee;

    & span {
        color: #eeeeee;
    }
}

.badge.success {
    border-color: var(--mint);

    & .icon-svg {
        color: var(--mint);
    }
}

.badge.warning {
    border-color: var(--bronze);

    & .icon-svg {
        color: var(--bronze);
    }
}

.badge.error {
    border-color: var(--indian-red);

    & .icon-svg {
        color: var(--indian-red);
    }
}