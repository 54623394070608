:root {
        /*
        --gradient-0: linear-gradient(120deg,
                        hsl(236, 65%, 29%) 0%,
                        hsl(235deg 62% 29%) 4%,
                        hsl(234deg 59% 29%) 8%,
                        hsl(233deg 56% 29%) 13%,
                        hsl(232deg 53% 28%) 18%,
                        hsl(231deg 50% 28%) 24%,
                        hsl(230deg 47% 28%) 30%,
                        hsl(226deg 49% 28%) 39%,
                        hsl(217deg 56% 29%) 50%,
                        hsl(207deg 64% 29%) 61%,
                        hsl(198deg 70% 29%) 72%,
                        hsl(189deg 77% 30%) 82%,
                        hsl(180deg 85% 30%) 91%,
                        hsl(171, 92%, 30%) 100%);
                        */

        --gradient-0: linear-gradient(120deg,
                        #3C398D 0%,
                        #E4003C 100%);

        --gradient-1: linear-gradient(120deg,
                        #3C398D 0%,
                        #E4003C 100%);

        /* System Colors by https://coolors.co/ */
        --anti-flash-white: #EBEBEB;
        --bronze: #CD7F32;
        --cerulean: #187498;
        --cornell-red: #A41313;
        --delft-blue: #333371;
        --dim-gray: #646369;
        --eletric-purple: #A64EF9;
        --fawn: #FFBC70;
        --field-drab: #4C3F05;
        --fire-engine-red: #C91818;
        --ghost-white: #F5F5F9;
        --glaucous: #5E7CC7;
        --gold: #FFD700;
        --indian-red: #EB5353;
        --jasmine: #FFE785;
        --jet: #3C3B3F;
        --jungle-green: #26A17B;
        --lapis-lazuli: #0C5D97;
        --marian-blue: #363681;
        --magnolia: #EBEBF6;
        --maya-blue: #7BC2F4;
        --mint: #44C58F;
        --moonstone: #54B8CC;
        --money: #2c5c4f;
        --onyx: #46454A;
        --oxford-blue: #12122B;
        --picton-blue: #21A1D4;
        --platinum: #D9D9D9;
        --polynesian-blue: #2653A4;
        --princeton-orange: #FF961F;
        --jet: #28282A;
        --rich-black: #0C0C1D;
        --rsid-blue: #0057FF;
        --sea-green: #2C8C64;
        --silver: #A6A6A6;
        --slate-blue: #6666bf;
        --space-cadet: #161635;
        --space-cadet-1: #242456;
        --tea-green: #CCDDB7;
        --united-nations-blue: #1D96EE;
        --vanila: #F8E8A0;
        --viridian: #3C7C6B;
        --white: #FFFFFF;
        --white-smoke: #F5F5F5;
        --zomp: #07A588;
}