#message-popup-column {
    bottom: 2rem;
    position: absolute !important;
    right: 2rem;
    z-index: 2000;
}

.message-popup-container {
    display: block;
    font-weight: bold;
    height: fit-content;
    margin-top: var(--margin-primary) !important;
    max-width: 30rem;
    position: relative;
}

.message-popup-container .icon {
    align-items: center;
    border-radius: 100%;
    display: flex;
    height: 1.75rem;
    justify-content: center;
    width: 2.25rem;
}

.message-popup-container .icon svg {
    color: rgba(0, 0, 0, .5);
}

.message-popup-container .message-popup {
    padding: var(--padding-quaternary) var(--padding-secondary) !important;
}

.message-popup-container.error {
    & #time-to-close-bar {
        background-color: var(--indian-red) !important;
    }

    & .icon svg {
        color: var(--indian-red) !important;
    }
}

.message-popup-container.info {
    & #time-to-close-bar {
        background-color: var(--maya-blue) !important;
    }

    & .icon svg {
        color: var(--maya-blue) !important;
    }
}

.message-popup-container.success {
    & #time-to-close-bar {
        background-color: var(--jungle-green) !important;
    }

    & .icon svg {
        color: var(--jungle-green) !important;
    }
}

.message-popup-container.warning {
    & #time-to-close-bar {
        background-color: orange !important;
    }

    & .icon svg {
        color: orange !important;
    }
}


.message-popup-container :is(p, svg) {
    font-size: medium;
}

.message-popup-container svg {
    height: 1.25rem;
}

.message-popup-container #close-popup {
    cursor: pointer;
}

.message-popup-container #time-to-close-bar {
    animation: barAnimation 3s linear;
    border-radius: var(--border-radius);
    bottom: 0;
    height: .15rem;
    left: 0;
    position: absolute;
    right: 0;
}