.card {
    border: none !important;
    width: fit-content;

    & .card-title {
        color: var(--color-secondary);
        font-size: x-small;
        font-weight: 400;
    }

    /* ARTICLE PREVIEW ======================== */

    &.article-preview {
        cursor: pointer;
        height: fit-content;
        transition: var(--transition-secondary);
        user-select: none;

        &:hover {
            background: var(--anti-flash-white)
        }

        & .banner-container {
            position: relative;
            width: 100%;
            height: 5rem;
            overflow: hidden; 
        }
        
        & .banner-container .banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        & h2 {
            font-size: small;
            font-weight: 400;
        }

        & .source-icon {
            border-radius: 0.25rem;
            height: 1.5rem;
        }
    }
}

body.dark .card {
    border: none !important;

    &.article-preview {
        &:hover {
            background: var(--jet);
        }
    }
}