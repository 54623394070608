input {
    background: var(--input-bg-color);
    border: var(--border-primary);
    border-radius: var(--border-radius);
    color: var(--input-color);
    font-size: small;
    height: 2.5rem;
    transition: var(--transition-secondary);

    &.searchbar {
        height: 2rem;
        width: 10rem;
        padding: 0 var(--padding-primary) !important;
    }
}

.input-action {
    padding: 0 var(--padding-secondary);
    position: absolute;
    right: 0;
}

body.dark input {
    background: var(--input-dark-bg-color);
    color: var(--input-dark-color);
}

input:focus-within {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input.required {
    border: var(--border-secondary);
    transition: var(--transition-secondary);
}

.row {
    align-items: center;
    position: relative;

    & input {
        padding: 0 3.5rem 0 var(--padding-tertiary);
    }

    & .show-icon {
        margin-right: var(--margin-secondary);
        position: absolute;
        right: 0;
    }

    &  .show-icon .switchable-icon {
        padding: var(--padding-quaternary) var(--padding-primary);
    }

    & .show-icon svg {
        height: 1.5rem;
    }

    & .unit {
        font-size: large;
        max-width: unset;
        min-width: unset;
        padding: 0 var(--margin-secondary);
        text-align: center;
        width: 1rem !important;
    }

    & .info-text {
        position: absolute;
        top: 3.5rem;
    }
}

input.text-minimal {
    background: unset;
    border-bottom: .1rem solid var(--color-tertiary);
    border-radius: unset;
    height: fit-content;
    margin: 0;
    margin-right: var(--margin-primary);
    padding: var(--padding-quaternary);
}

body.dark input.text-minimal {
    background: unset;
}

.input-blue-focus:focus {
    outline: .1rem solid var(--united-nations-blue);
    outline-offset: -.1rem;
}