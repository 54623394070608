
.switchable-icon {
    border-radius: var(--border-radius);
    cursor: pointer;
    padding: .5rem;
    transition: var(--transition-secondary);

    & p {
        margin-left: var(--margin-primary);
        user-select: none;
    }

    &.disabled {
        opacity: .25;

        &:hover {
            background: unset !important;
        }
    }
}

.switchable-icon:hover {
    background: #eeeeee;
}

:is(body.dark .header, .header) .switchable-icon:hover {
    background: none;
}

body.dark .switchable-icon:hover {
    background: var(--jet);
}
