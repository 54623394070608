.bot-card {
    height: fit-content !important;
    margin: .5rem !important;
    background-color: var(--ghost-white) !important;
}

body.dark .bot-card {
    background-color: var(--space-cadet) !important;
}

.bot-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bot-card-title svg {
    width: 2.5rem;
}

.bot-card-title p {
    font-size: large !important;
}

.bot-card-title div {
    display: flex;
    align-items: center;
}

.bot-card-title .social-media-icon {
    margin-right: .75rem;
}

.bot-card-content span {
    display: flex;
}

.bot-card-content span :is(.simple-badge, b, p) {
    margin: .75rem .5rem 0 0;
}
