.label {
    cursor: pointer;
    user-select: none;

    & img {
        height: 2rem;
    }

    &.coin-info {
        & .base-icon {
            border: .2rem solid var(--container-bg-color);
            border-radius: 100%;
            position: absolute;
        }

        & .quote-icon {
            margin-bottom: 1rem;
            margin-left: 1.75rem;
            height: 1.25rem !important;
        }

        & .coin-symbol {
            color: var(--color-primary);
            cursor: default;
            font-size: small;
            line-height: 1rem;
        }


        & .coin-name {
            color: var(--color-tertiary);
            font-size: x-small !important;
            font-weight: 400;
            height: 0px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: var(--transition-primary);
        }
    }

    &.coin-info:hover .coin-name {
        height: 1rem;
    }
}

body.dark .label {
    &.coin-info {
        & .base-icon {
            border: .2rem solid var(--container-bg-color-dark);
        }

        & .coin-symbol {
            color: var(--color-primary-dark);
        }
    }
}