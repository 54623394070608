.ats-reports {
    & .main-row {

        .line-chart {
            height: 10rem !important;
        }

        & .icon-svg.warning {
            color: var(--bronze)
        }

        & #execution-list-header {
            & .col-2 {
                margin-right: var(--padding-secondary);
            }
        }

        & #executions-results-list {
            & .execution-list-item {
                cursor: pointer;
                border-radius: var(--border-radius);
                padding: .25rem;

                &.selected {
                    background-color: var(--anti-flash-white);
                }

                &:hover {
                    background-color: var(--anti-flash-white);
                }
            }

            & .badge {
                width: 2rem;
            }
        }

        & .col-0 {
            width: 8rem !important;
        }

        & .col-1 {
            text-align: center;
            width: 3rem !important;
        }

        & .col-2 {
            margin-right: var(--padding-primary);
            width: 10rem !important;
        }

        & .execution-details-date {
            font-weight: bold;
            font-size: large;
            opacity: .5;
        }

        & .negotiation-overview {
            gap: var(--gap-primary) 2rem;
        }
    }
}

body.dark .ats-reports {
    & .main-row {
        & #executions-results-list {
            & .execution-list-item {
                &.selected {
                    background-color: var(--jet);
                }

                &:hover {
                    background-color: var(--jet);
                }
            }
        }
    }
}