.multi-factor-step {
    width: 100%;

    .title {
        text-align: center;
        width: 100%;
    }

    .continue-button {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        padding: var(--padding-primary);
    }

    .mfa-tabs {
        width: 28rem !important;
    }

    .ant-tabs-nav::before {
        position: absolute;
        right: 0;
        left: 0;
        border-bottom: none;
        content: '';
    }

    .ant-tabs-nav {
        justify-content: center !important;
        font-weight: 600;
    }

    .ant-tabs-tab-btn {
        color: #757575;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #FFF;
    }

    .ant-tabs .ant-tabs-tab-btn:active {
        color: #FFF !important;
    }

    .ant-tabs-ink-bar {
        display: none !important;
    }

    .error-text {
        color: #ff4d4f;
        font-size: 0.85em;
        margin-top: 4px;
    }
    
    .phone-input-container {
        width: 100%;
    }
}
