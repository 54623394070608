#overview {

    & #main-column {
        overflow-x: hidden;
    }

    & #current-pnl {
        width: 5rem;
    }

    & #balances-overview,
    #last-news {
        box-sizing: border-box;
    }

    & #timeline {
        & .timeline-filter {
            padding: var(--padding-quaternary) var(--padding-primary);
        }

        & .content {
            display: flex;
            flex-direction: column;
        }

        & #plot-legend {
            display: flex;
            font-size: medium !important;
            align-items: center;

            & p {
                margin: 0 var(--margin-primary);
            }
        }

        & #date-label {
            color: var(--color-tertiary);
        }

        & #target-label {
            color: var(--jungle-green)
        }

        & #variation-label {
            color: var(--polynesian-blue)
        }
    }

    & #last-trades {
        box-sizing: border-box;
    }

    & #show-all-portfolio-coins-text {
        font-size: small;
    }
}

body.dark #overview {
    & #current-balance {
        color: var(--color-primary-dark);
    }
}